import { Link, NavigationType, useNavigate } from "react-router-dom";
import { IIntent, callApi } from "./Services/Api";
import React, { useState } from "react";
import { NavigationMap } from "./Services/NavigationMap";

interface IState {
    items: IIntent[];
}

export const App: React.FC = () => {

    var nav = useNavigate();
    var navigator = new NavigationMap(nav);

    const [state, setState] = useState<IState>();

    if (state == null) {
        callApi(async (api) => {
            if (!(api.isAuthenticated)) {
                navigator.goToLogin();
                return;
            }

            var items = await api.getIntentsAsync();
            setState({
                items: items
            });
        });
    }

    const Book = (props: IIntent) => {
        const { name, token } = props;

        var url = navigator.getIntentUrl(props);

        return (
            <div>
                <Link to={url}>{name}</Link>
            </div>
        );
    };

    if (state != null) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="App">
                            <h1>Topics</h1>
                            {state.items.map(book => {
                                return (
                                    <div className="card" key={book.token}>
                                        <div className="card-body bg-light">
                                            <Book {...book} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>Loaded...</div>
        )
    }
}

export default App;
