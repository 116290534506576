import { NavigateFunction } from "react-router-dom";
import { IIntent } from "./Api";

export class NavigationMap {
    private navigator: NavigateFunction;

    constructor(navigator: NavigateFunction) {
        this.navigator = navigator;
    }

    goToHome() {
        this.navigator("/");
    }

    goToLogin() {
        this.navigator("/login");
    }

    getIntentUrl(item: IIntent) {
        return "/intent?token=" + encodeURIComponent(item.token);
    }
}