import $ from 'jquery';
import Cookie from 'js-cookie';

export interface IIntent {
    token: string;
    name: string;
};

export interface ISample {
    token: string;
    sample: string;
}

export interface IResponseBase {
    errors: string[];
    hasErrors: boolean;
}

export interface ICheckSessionResponse {
    token: string;
    isAuthenticated: boolean;
}

export interface ILoginRequest {
    username?: string;
    password?: string;
    loginData?: any;
}

export interface ILoginResponse extends IResponseBase {
}

export class Api {
    token: string | null = null;
    isAuthenticated: boolean = false;

    private getServiceUri(uri: string): string {
        if (!((uri as any).startsWith("/")))
            uri = "/" + uri;
        return "https://api.sidekicksupport.co.uk" + uri;
    }

    private getAsync<T>(uri: string): Promise<T> {
        return this.callApiAsync(uri, "GET", null);
    }

    private postAsync<T>(uri: string, data: any): Promise<T> {
        return this.callApiAsync(uri, "POST", data);
    }

    private putAsync<T>(uri: string, data: any): Promise<T> {
        return this.callApiAsync(uri, "PUT", data);
    }

    private deleteAsync<T>(uri: string): Promise<T> {
        return this.callApiAsync(uri, "DELETE", null);
    }

    private async callApiAsync<T>(uri: string, method: string, data: any): Promise<T> {
        var uri = this.getServiceUri(uri);
        console.log("calling up with data --> ", data);
        var result = await $.ajax({
            url: uri,
            method: method,
            data: JSON.stringify(data),
            contentType: "application/json",
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                "x-eddie": this.token
            }
        });
        return result;
    }

    checkSessionAync(token: string): Promise<ICheckSessionResponse> {
        return this.postAsync<ICheckSessionResponse>("/application/checksession", {
            value: token
        });
    }

    getIntentsAsync(): Promise<IIntent[]> {
        return this.getAsync<IIntent[]>("/intents");
    }

    getIntentAsync(token: string): Promise<IIntent> {
        return this.getAsync<IIntent>("/intents/" + encodeURIComponent(token));
    }

    getSamplesForIntentAsync(item: IIntent): Promise<ISample[]> {
        return this.getAsync<ISample[]>("/intents/" + encodeURIComponent(item.token) + "/samples");
    }

    addSampleToIntentAsync(item: IIntent, newSample: string): Promise<IResponseBase> {
        return this.postAsync<IResponseBase>("/intents/" + encodeURIComponent(item.token) + "/samples/add", {
            value: newSample
        });
    }

    prepLoginRequestAsync(): Promise<ILoginRequest> {
        return this.getAsync<ILoginRequest>("/application/preplogin");
    }

    loginAsync(request: ILoginRequest): Promise<ILoginResponse> {
        return this.postAsync<ILoginResponse>("/application/login", request);
    }
}

export async function callApi(callback: (api: Api) => void): Promise<void> {

    var api = new Api();

    const cookieName = "__marsala";
    var token = String(Cookie.get(cookieName));
    var response = await api.checkSessionAync(token);
    Cookie.set(cookieName, response.token);
    api.token = response.token;
    api.isAuthenticated = response.isAuthenticated;

    callback(api);  
}

export function isOk(response: IResponseBase, callback: () => void): void {
    if (!(response.hasErrors))
        callback();
    else
        showErrors(response);
}

function showErrors(response: IResponseBase) {
    var buf = "";
    for (let error of response.errors) {
        if (buf.length > 0)
            buf += "\r\n";
        buf += error;
    }
    alert(buf);
}