import { Link, NavigationType, useNavigate, useSearchParams } from "react-router-dom";
import { IIntent, callApi, Api, ISample, isOk } from "../Services/Api";
import React, { useState } from "react";
import { NavigationMap } from "../Services/NavigationMap";

interface IState {
    item: IIntent;
    samples: ISample[];
}

export const Intent : React.FC = () => {

    const nav = useNavigate();
    var navigator = new NavigationMap(nav);

    const [qs] = useSearchParams();

    const [state, setState] = useState<IState>();
    const [newSample, setNewSample] = useState<string>("");

    const refreshView = () => {
        callApi(async (api) => {
            const token = qs.get("token") as string;
            var intent = await api.getIntentAsync(token);
            var samples = await api.getSamplesForIntentAsync(intent);
            setState({
                item: intent,
                samples: samples
            });
        });
    }

    if (state == null)
        refreshView();

    const addNew = () => {
        callApi(async (api) => {
            if (!(api.isAuthenticated)) {
                navigator.goToLogin();
                return;
            }

            var response = await api.addSampleToIntentAsync(state!.item, newSample!);
            isOk(response, () => {
                setNewSample("");
                refreshView();
            });
        });
    }

    if (state != null) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="App">
                            <h1>Intent: {state.item.name}</h1>
                        </div>
                        <div>
                            <Link to="/">Back</Link>
                        </div>

                        <h2>Samples</h2>
                        {state.samples!.map((sample) => {
                            return (
                                <div className="form-group" key={sample.token}>
                                    <input className="form-control" value={sample.sample}></input>
                                </div>
                            )
                        })}

                        <div className="margin-top-lg">
                        </div>

                        <div className="form-group">
                            <b>Add New</b>
                            <input className="form-control" value={newSample} onChange={(e) => setNewSample(e.target.value)}></input>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-sm btn-success" onClick={addNew}>Add New</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    } else {
        return (
        <div>Loading...</div>
        )
    }
}

export default Intent;