import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { callApi, ILoginRequest, isOk } from "../Services/Api";
import { NavigationMap } from "../Services/NavigationMap";

export const Login: React.FC = () => {

    const nav = useNavigate();
    var navigator = new NavigationMap(nav);

    const [request, setRequest] = useState<ILoginRequest>();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const login = () => {
        callApi(async (api) => {
            request!.username = username;
            request!.password = password;
            var response = await api.loginAsync(request!);
            isOk(response, () => {
                navigator.goToHome();
            });
        });
    }

    if (request == null) {
        callApi(async (api) => {
            let request = await api.prepLoginRequestAsync();
            setRequest(request);
        });
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="App">
                        <h1>Login</h1>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-sm btn-success" onClick={login}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};